<template>
  <div>
    <b-card no-body class="mb-0">
      <header class="m-2">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="grow">Cuentas de Stripe</h2>

          <div class="d-flex align-items-center justify-content-end">
            <b-button
              class="add-button-rounded"
              variant="success"
              @click="showModal = true"
            >
              <feather-icon icon="PlusIcon" size="18" />
            </b-button>
          </div>
        </div>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <small>
              Las cuentas de Stripe se utilizan para que tus clientes puedan
              comprarte en linea y pagar con tarjetas bancarias o mediante
              transferencias o depósitos bancarios.
              <br><br>
              La comisión por pago con tarjeta bancarias es del 4.6% + $3.00 + IVA por transacción.
              <br><br>
              La comisión por pago por transferencia o depósito bancario es de $17.00 + IVA por transacción.
            </small>
          </b-col>
        </b-row>
      </header>

      <store-accounts-list
        :establishmentAccounts="establishmentAccounts"
        :handleUpdateStatus="handleUpdateStatus"
        :handleSelectedStripeAccount="handleSelectedStripeAccount"
        :handleOnboarding="handleOnboarding"
      />

      <b-table
        class="position-relative stripe-table"
        :items="establishmentAccounts"
        :fields="tableColumns"
        responsive
        primary-key="id"
        show-empty
        empty-text="No existen registros aún"
        tdClass="p-0"
      >
        <template #cell(linked_stores)="data">
          <div class="d-flex flex-wrap gap">
            <b-badge
              v-for="(store, index) in data.item.linked_stores"
              size="sm"
            >
              {{ store.name }}
            </b-badge>
          </div>
        </template>

        <template #cell(active_status)="data">
          <b-badge
            size="sm"
            class="mx-1"
            :variant="data.item.active_status ? 'success' : 'danger'"
          >
            {{ data.item.active_status ? "Activado" : "Desactivado" }}
          </b-badge>
        </template>

        <template #cell(payouts)="data">
          <div class="d-flex">
            Cargos:
            <b-badge
              size="sm"
              class="mx-1"
              :variant="data.item.charges_enabled ? 'success' : 'danger'"
            >
              {{ data.item.charges_enabled ? "Activado" : "Desactivado" }}
            </b-badge>
          </div>
          <div class="d-flex mt-1">
            Payouts
            <b-badge
              size="sm"
              class="mx-1"
              :variant="data.item.payouts_enabled ? 'success' : 'danger'"
            >
              {{ data.item.payouts_enabled ? "Activado" : "Desactivado" }}
            </b-badge>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="handleUpdateStatus(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{
                data.item.active_status ? "Desactivar" : "Activar"
              }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="handleSelectedStripeAccount(data.item)">
              <feather-icon icon="LinkIcon" />
              <span class="align-middle ml-50">Vincular tienda</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="handleOnboarding(data.item)"
              v-if="!data.item.onboarding_complete"
            >
              <feather-icon icon="LinkIcon" />
              <span class="align-middle ml-50">Completar onboarding</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Mostrando {{ establishmentAccountsPagination.per_page }} de
              {{ establishmentAccountsPagination.total_objects }}
              registros</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="establishmentAccountsPagination.page"
              :total-rows="establishmentAccountsPagination.total_objects"
              :per-page="establishmentAccountsPagination.per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="
                (value) => {
                  handlePagination({
                    page: value,
                    per_page: establishmentAccountsPagination.per_page,
                  });
                }
              "
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <b-spinner v-if="loading" small label="Loading" />
                <feather-icon v-else icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal v-model="showModal">
      <validation-observer ref="aliasRules" v-slot="{ invalid }">
        <h4 class="text-center">
          Ingresa un alias para la nueva cuenta de Stripe
        </h4>
        <b-form class="mt-2" @submit.prevent="handleSubmit()">
          <b-form-group label="Alias" label-for="alias">
            <validation-provider
              #default="{ errors }"
              name="alias"
              rules="required"
            >
              <b-form-input
                v-model="aliasAccount"
                :state="errors.length > 0 ? false : null"
                placeholder="ALIAS"
                name="alias"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Tipo de negocio" label-for="business-type">
            <validation-provider
              #default="{ errors }"
              name="business-type"
              rules="required"
            >
              <v-select
                v-model="businessType"
                :options="[
                  { value: 'individual', text: 'Individual' },
                  { value: 'company', text: 'Empresa' },
                ]"
                :state="errors.length > 0 ? false : null"
                label="text"
                clearable
                placeholder="Tipo de negocio"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="handleStripeAccountSubmit()">
          Guardar
        </b-button>
        <b-button variant="outline-secondary" @click="cancel()">
          Cancelar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="bv-modal-link-store" no-close-on-backdrop>
      <div>
        <h4 class="text-center">Selecciona una tienda para vincular</h4>
        <v-select
          v-model="selectedStore"
          :options="onlineStores"
          label="name"
          :clearable="false"
          :placeholder="'Selecciona una tienda'"
        />
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="handleStripeAccountAttach()">
          Vincular tienda
        </b-button>
        <b-button variant="outline-secondary" @click="cancel()">
          Cancelar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

import StoreAccountsList from "@/views/stripe/StoreAccountsList.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,

    StoreAccountsList,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      tableColumns: [
        {
          label: "Alias",
          key: "alias",
          sortable: true,
          tdClass: "p-1",
        },
        {
          label: "Tiendas vinculadas",
          key: "linked_stores",
          sortable: true,
          tdClass: "p-1",
        },
        {
          label: "Estatus",
          key: "active_status",
          sortable: true,
          tdClass: "p-1",
        },
        {
          label: "PAYOUTS",
          key: "payouts",
          tdClass: "p-1",
        },
        {
          label: "Acciones",
          key: "actions",
          tdClass: "p-1",
        },
      ],
      showModal: false,
      aliasAccount: null,
      tabledata: [],
      selectedStore: null,
      selectedStripeAccount: null,
      loading: false,
      newAlias: null,
      businessType: null,
    };
  },
  computed: {
    ...mapGetters("stripe", [
      "establishmentAccounts",
      "storeAccount",
      "establishmentAccountsPagination",
    ]),
    ...mapGetters("stores", ["onlineStores"]),
  },
  beforeMount() {
    this.$swal({
      title: "Cargando...",
      allowOutsideClick: false,
      didOpen: () => {
        this.$swal.showLoading();
      },
    });
    this.fetchOnlineStores({
      by_establishment: this.$route.params.id,
      meta: { pagination: { page: 1, per_page: 100000 } },
    });
    this.fetchEstablishmentAccounts({
      establishment_id: this.$route.params.id,
    }).then((res) => {
      this.$swal.close();
    });
  },
  methods: {
    ...mapActions("stripe", [
      "createEstablishmentAccount",
      "fetchEstablishmentAccountLink",
      "fetchEstablishmentAccounts",
      "completeOnboarding",
      "createStoreAccount",
      "fetchStoreAccount",
      "updateEstablishmentStripeAccount",
    ]),
    ...mapActions("stores", ["fetchOnlineStores"]),

    handleUpdateStatus(item) {
      this.updateEstablishmentStripeAccount({
        establishment_id: this.$route.params.id,
        active_status: !item.active_status,
        stripe_account_id: item.stripe_account_id,
      })
        .then((res) => {
          this.$swal({
            title: "Estatus actualizado!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.fetchEstablishmentAccounts({
            establishment_id: this.$route.params.id,
          });
        })
        .catch((e) => {
          const errorMessage = e.response.data.messages;
          this.$swal({
            title: "Error al actualizar el estatus",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    handleSelectedStripeAccount(item) {
      this.selectedStripeAccount = item;
      this.$bvModal.show("bv-modal-link-store");
    },
    handleStripeAccountAttach() {
      if (!this.selectedStore) {
        this.$swal({
          title: "Selecciona una tienda a vincular",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      this.createStoreAccount({
        store_account: {
          store_id: this.selectedStore.id,
          establishment_account_id: this.selectedStripeAccount.id,
        },
      })
        .then((res) => {
          this.selectedStore = null;
          this.$swal({
            title: "Cuenta agregada!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.$bvModal.hide("bv-modal-link-store");
          this.fetchEstablishmentAccounts({
            establishment_id: this.$route.params.id,
          });
        })
        .catch((e) => {
          this.$bvModal.hide("bv-modal-link-store");
          this.$swal({
            title: "Error al agregar la cuenta",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    handleStripeAccountSubmit() {
      this.$refs.aliasRules.validate().then((success) => {
        if (success) {
          this.$swal({
            title: "Creando cuenta...",
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading();
            },
          });
          this.createEstablishmentAccount({
            establishment_account: {
              establishment_id: this.$route.params.id,
              alias: this.aliasAccount,
              country: "MX",
              business_type: this.businessType,
            },
          })
            .then((res) => {
              this.fetchEstablishmentAccountLink({
                establishment_id: this.$route.params.id,
                stripe_account_id:
                  res.stripe_establishment_account.stripe_account_id,
              }).then((res) => {
                this.$swal.close();
                const url = res.account_link_url;

                window.open(url, "_blank");
              });
            })
            .catch((e) => {
              const message = e.response.data.messages;
              this.$swal({
                title: message,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handlePagination({ page, per_page }) {
      this.loading = true;
      this.fetchEstablishmentAccounts({
        establishment_id: this.$route.params.id,
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      }).then((res) => {
        this.loading = false;
      });
    },
    handleOnboarding(item) {
      this.completeOnboarding({
        establishment_id: this.$route.params.id,
        stripe_account_id: item.stripe_account_id,
      })
        .then((res) => {
          const url = res.account_link_url;
          window.open(url, "_blank");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-button-rounded {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gap {
  gap: 0.5rem;
}

.stripe-table {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.stripe-list {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}
</style>
